@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap);
/* General Syle */

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p.capitalize {
  text-transform: capitalize;
}

button {
  border-radius: 25px !important;
  margin-right: 5px !important;
}

input {
  border-radius: 10px !important;
}

textarea {
  padding: 15px !important;
  border-radius: 10px !important;
}
.custom-container-modal {
  outline: 0;
  background-color: white;
  border: 1px solid grey;
  padding: 2%;
  border-radius: 25px;
  opacity: 1 !important;
  margin: 10%;
  margin-top: 2%;
  z-index: 20000 !important;
  overflow: scroll;
}
.modal-view-richiesta p {
  margin-top: 0px !important;
  margin-bottom: 15px !important;
  padding-bottom: 0px !important;
  line-height: 1em !important;
}
.uk-modal-dialog {
  padding: 30px;
  border-radius: 25px;
}

.big-close svg {
  width: 35px !important;
  height: 35px !important;
}

.logo-pages {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 250px;
}

.logo-pages-menu {
  width: 75px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

h4 {
  line-height: 1em;
}

.main-page {
  min-height: 80vh;
}

.img-account-cover {
  max-width: 90px !important;
  height: 90px;
  border-radius: 100%;
  object-fit: cover;
}

/* Home */

.input-text-home {
  margin-top: 15px;
  width: 90%;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
  font-size: 1.4em;
  font-weight: normal !important;
}

.input-text-home:focus {
  outline: none !important;
  border: 1px solid #6495ed;
}

.user-find {
  text-align: left;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}

.header-custom-find-user {
  text-align: center;
}

.circle-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 15px;
  margin-left: 15px;
  object-fit: cover;
}

.custom-icon-richieste {
  background-color: #6495ed;
  color: white;
  width: 30px;
  height: 30px;
}
.custom-icon-richieste:hover {
  cursor: pointer;
}
.color-default {
  color: #6495ed !important;
  text-transform: capitalize;
}

.result-custom-home {
  margin-top: 20px;
  margin-bottom: 10px;
}

/* PAZIENTE */

.custom-card-paziente {
  padding: 40px 20px 40px 20px;
}

.custom-card-paziente p {
  font-size: 1em;
  font-weight: 300 !important;
}

.custom-card-paziente h4 {
  line-height: 1.1em;
  font-size: 1.3em;
}
button:focus {
  outline: none !important;
}
.custom-icon-copy {
}
.uk-icon-button:hover {
  cursor: pointer;
}
.custom-icon-send {
  background-color: #3cb371;
  color: white;
}
.uk-select {
  border-radius: 15px;
}
.custom-icon-send-blue {
  background-color: #3cb371;
  color: white;
}
.custom-icon-send-blue:hover {
  cursor: pointer;
}
.table-richieste-custom {
}

/* AUTO SUGGESTION */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  max-width: 100%;
  height: 30px;
  padding: 0 10px;
  font-size: 1em;
  color: #666 !important;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
  font: inherit;
  max-width: 100%;
  width: 100%;
  border: 0 none;
  padding: 0 10px;
  background: #fff;
  color: black;
  border: 1px solid #e5e5e5;
  transition: 0.2s ease-in-out;
  margin-bottom: 0;
  padding: 19px 10px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.spinner-loading-center {
  text-align: center;
  margin-top: 50px;
  width: 100%;
}

/*Calendario */

.disabled-text {
  color: lightgray;
}

.disabled-text:hover {
  background-color: white !important;
  cursor: default !important;
}

.uk-table td {
  overflow-wrap: break-word;
}

/* SCROLL BAR */

::-webkit-scrollbar {
  width: 10px;
  margin-right: 5px !important;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  margin: 5px 10px !important;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 3px;
  margin-right: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: gray;
}

/* SWALL */

.swal-modal {
  font-family: "Raleway", sans-serif !important;
  border-radius: 25px !important;
}

.swal-button--confirm {
  font-weight: 300;
  text-transform: uppercase;
  border: 1px solid #1e87f0;
  background-color: #1e87f0;
}

.swal-button--confirm:hover {
  background-color: #0f7ae5 !important;
}

/* PAZIENTI */
.custom-grid {
  text-decoration: underline;
  cursor: pointer;
}
.custom-grid th {
  font-weight: bold !important;
  color: black;
}

.custom-tr-farmaci {
  border-bottom: 1px solid lightgray !important;
  text-transform: capitalize;
  padding: 0px !important;
  margin: 0px !important;
}

.custom-tr-farmaci td {
  padding-bottom: 0px !important;
  margin-bottom: 10px;
}

.custom-tr-farmaci td a {
  width: 15px;
  height: 15px;
}

.custom-tr-farmaci td span {
  width: 15px;
  line-height: 1em !important;
  height: 15px;
}

.custom-tr-farmaci .uk-radio {
  margin-right: 15px;
}

.result-farmaci {
  max-height: 400px;
  overflow-y: scroll;
}

.tr-richiesta th {
  color: black;
}
.custom-icon-copy {
  background-color: #0f7ae5;
  color: white !important;
}
.custom-icon-copy:hover {
  background-color: lightblue;
  color: white !important;
  cursor: pointer;
}
#modal-add-richiesta .uk-modal-close-default svg {
  width: 25px;
  height: 25px;
}

.scheda-clienti {
  margin-bottom: 25px;
}

.scheda-clienti p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.scheda-clienti a {
  color: rgb(102, 102, 102);
  text-decoration: underline;
}

.principio-attivo-span {
  font-size: 0.8em !important;
  padding-left: 30px;
  line-height: 1em;
}

.button-remove-farmaco {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  font-size: 0.9em;
  margin-left: 0px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;

  background-color: white;
  cursor: pointer;
  margin-left: 25px;
}

.uk-accordion-content {
  margin-top: 5px;
}

.button-remove-farmaco:hover {
  background-color: #ff4d4d;
  color: white;
  border: 1px solid #ff4d4d;
}

.button-remove-farmaco:hover::after {
  content: "X";
}

.button-remove-farmaco::after {
  content: "X";
  border-radius: 50%;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 10px;
}

.form-richiesta-custom {
  margin-top: 15px;
}

.parag-custom-richiesta {
  overflow: auto;
}

.custom-p-margin p {
  margin-bottom: 0px;
  margin-top: 5px;

  padding-bottom: 0px;
}

.padding-left-farmaci {
  padding-left: 15px;
  font-size: 0.9em;
}

.uk-table td {
  padding: 10px 12px !important;
  border-bottom: 1px solid lightgray;
  vertical-align: top;
}

.collapse-insert a {
  font-size: 0.9em;
  border-bottom: 1px solid lightgray;
  text-decoration: underline;
}

.header-nav nav {
  background-color: white;
}

.button-send {
  background-color: white;
  border: 1px solid grey;
  padding: 10px 25px;
  font-weight: 300;
  font-size: 1.2em;
  margin-right: 30px !important;
  cursor: pointer;
}

.button-send:hover {
  background-color: red;
}

.button-send:focus {
  outline: 0;
}

.button-whatsapp {
  border: 1px solid #25d366;
  color: #25d366;
}

.button-whatsapp:hover {
  color: white;
  background-color: #25d366;
  border: 1px solid #25d366;
}

.button-email {
  border: 1px solid orange;
  color: orange;
}

.button-email:hover {
  color: white;
  background-color: orange;
  border: 1px solid orange;
}

.button-sms {
  color: #004f9f;
  border: 1px solid #004f9f;
}

.button-sms:hover {
  color: white;
  background-color: #004f9f;
  border: 1px solid #004f9f;
}

/* switch */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
.pdf-align-center {
  vertical-align: -webkit-baseline-middle !important;
  vertical-align: -moz-middle-with-baseline !important;
}
input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}
.rbc-event-label {
  display: none !important;
}
.rbc-event {
  border-radius: 5px !important;
  margin-top: 5px !important;
  padding-bottom: 24px !important;
}
.rbc-event-content {
  padding-top: 0px;
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.react-datepicker-popper {
  z-index: 10000 !important;
}
.datepicker {
  z-index: 1151 !important;
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 2000;
}
#loader {
  text-align: center;
  position: absolute;
  margin: auto;
  top: 0px;
  left: 0;
  background-color: white;
  z-index: 2000;
  width: 100%;
  height: 100vh;
}
#loader span {
  position: relative;
  top: 30%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.color-white-svg {
  -webkit-filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(113deg)
    brightness(103%) contrast(100%);
          filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(113deg)
    brightness(103%) contrast(100%);
}

.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
/*FINE SWITCH*/
.rbc-event-content {
  line-height: 1.5em !important;
}
.title-input {
  font-weight: bold;
  margin-bottom: 5px;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  font-family: "Raleway", sans-serif !important;
}

.react-datepicker__navigation--previous {
  border-right-color: black !important;
}

.react-datepicker__navigation--next {
  border-left-color: black !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: 1px solid black;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: lightgray !important;
}

.react-datepicker__current-month {
  text-transform: capitalize;
}

.react-datepicker__month-option {
  text-transform: capitalize;
  background-color: white;
}

.react-datepicker__month-read-view {
  text-transform: capitalize;
}

.react-datepicker__header {
  border-bottom: 0 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: white !important;
  border: 1px solid green;
  border-radius: 10px !important;
}

.react-datepicker__day--selected {
  background-color: white !important;
  border-radius: 25px !important;
  border: 1px solid green;
  color: green !important;
}

.react-datepicker__day--selected:focus {
  outline: 0 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 0px !important;
  padding-top: 10px !important;
}
.react-datepicker__time-list-item {
  color: green !important;
}
.react-datepicker__time-list-item--disabled {
  color: lightgray !important;
}
.react-datepicker {
  border: 1px solid #aeaeae;
  border-radius: 15px !important;
  padding: 15px;
}

.react-datepicker__year-dropdown {
  background-color: white !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  top: 27px;
  right: 120px;
}

.react-datepicker__navigation--previous {
  left: 30px !important;
  top: 27px !important;
}

.react-datepicker__time-container {
  border-left: 0px !important;
}

